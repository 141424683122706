export default function mobileMenu() {
  const btn = document.querySelector('.burger-menu');
  const menu = document.querySelector('.header__navigation');
  const html = document.querySelector('html');
  const body = document.querySelector('body');
  const header = document.querySelector('.header');
  if (!btn) return;

  btn.addEventListener('click', function () {
    this.classList.toggle('active');
    menu.classList.toggle('active');
    body.classList.toggle('overflow');
    html.classList.toggle('overflow');
    header.classList.toggle('active');
  });

  const sticky = header.offsetTop;

  function showOnScroll() {
    if (window.pageYOffset > sticky + header.offsetHeight) {
      header.classList.add("fixed");
      body.classList.add("body__header-fixed");
    } else {
      header.classList.remove("fixed");
      body.classList.remove("body__header-fixed");
    }
  }

  window.onscroll = function () {
    showOnScroll()
  };

  window.addEventListener('load', function () {
    menu.classList.remove('not-inited');
  });
}
