import mobileMenu from './modules/mobile-menu';
import insightsFilter from './modules/insights-filter';
import eventsSlider from './modules/events-slider';
import cardsList from './modules/cards';
import eventsFilter from './modules/events-filter';
import popupForm from './modules/popup-form';
import reportsSlider from './modules/reports';
import contactPage from './modules/contactPage';
import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  mobileMenu();
  insightsFilter();
  eventsSlider();
  cardsList();
  eventsFilter();
  popupForm();
  reportsSlider();
  contactPage();
});
