export default function floatLabels() {
  [...document.querySelectorAll('.form__field')].forEach((el) => {
    const item = el.querySelector('textarea, input:not([type=submit])');

    if (item) {
      item.addEventListener('focus', function () {
        this.parentElement.nextElementSibling.classList.add('filled');
      });

      item.addEventListener('blur', function () {
        if (!this.value) {
          this.parentElement.nextElementSibling.classList.remove('filled');
        }
      });
    }
  });
}
