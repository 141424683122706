import Swiper, { Navigation } from 'swiper';

export default function reportsSlider() {
  const reports = document.querySelectorAll('.reports');
  const mediaTablet = window.innerWidth < 769;

  if (!reports) return;

  reports.forEach((el) => {
    const slider = el.querySelector('.swiper');
    const prev = el.querySelector('.reports__prev');
    const next = el.querySelector('.reports__next');

    const mobPrev = el.querySelector('.reports__nav.mobile .reports__prev');
    const mobNext = el.querySelector('.reports__nav.mobile .reports__next');

    const swiper = new Swiper(slider, {
      modules: [Navigation],
      slidesPerView: 'auto',
      spaceBetween: 12,
      loop: true,
      loopedSlides: 10,
      breakpoints: {
        1025: {
          spaceBetween: 32
        }
      },
      navigation: {
        nextEl: mediaTablet ? mobNext : next,
        prevEl: mediaTablet ? mobPrev : prev
      }
    });

    swiper.init();
  });
}
