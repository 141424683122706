import floatLabels from './floatLabels';
import popupSuccess from './popup-success';

export default function popupForm() {
  const form = document.querySelector('.popup__form');

  if (!form) return;

  const formElem = document.querySelector('.popup__form-body');
  const close = document.querySelector('.popup__form-close');
  const links = document.querySelectorAll('.popup-open');
  const body = document.querySelector('body');
  const html = document.querySelector('html');

  links.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      form.classList.remove('hidden');
      body.classList.add('hidden');
      html.classList.add('hidden');
    });
  });

  close.addEventListener('click', () => {
    form.classList.add('hidden');
    body.classList.remove('hidden');
    html.classList.remove('hidden');
  });

  popupSuccess(body, html, formElem);
  floatLabels();
}
