import floatLabels from './floatLabels';
import popupSuccess from './popup-success';

export default function contactPage() {
  const contactForm = document.querySelector('.contact-page__form-form');

  if (!contactForm) return;

  const body = document.querySelector('body');
  const html = document.querySelector('html');

  popupSuccess(body, html, contactForm);
  floatLabels();
}
    