import Swiper, { Navigation } from 'swiper';

export default function eventsSlider() {
  const events = document.querySelectorAll('.events-slider');

  if (!events) return;

  events.forEach((el) => {
    const slider = el.querySelector('.swiper');
    const prev = el.querySelector('.events-slider__prev');
    const next = el.querySelector('.events-slider__next');

    const swiper = new Swiper(slider, {
      modules: [Navigation],
      slidesPerView: 'auto',
      spaceBetween: 10,
      allowTouchMove: false,
      speed: 1500,
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 12
        },
        991: {
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      },
      navigation: {
        nextEl: next,
        prevEl: prev
      }
    });

    swiper.init();
  });
}
