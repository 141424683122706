export default function eventsFilter() {
  const filter = document.querySelector('.events__filter form');

  if (!filter) return;

  const inputs = filter.querySelectorAll('input');

  inputs.forEach((el) => {
    el.addEventListener('change', () => {
      filter.submit();
      return false;
    });
  });
}
