export default function popupSuccess(body, html, formElem) {
  const wpcf7Elm = document.querySelector('.wpcf7');
  const formSection = document.querySelector('.popup__form');

  const closePopup = document.querySelector('.popup-success__close');
  const success = document.querySelector('.popup-success');

  wpcf7Elm.addEventListener(
    'wpcf7mailsent',
    () => {
      formElem.classList.add('hidden');
      success.classList.remove('hidden');
      body.classList.add('hidden');
      html.classList.add('hidden');
    },
    false
  );

  closePopup.addEventListener('click', () => {
    success.classList.add('hidden');
    if (formSection) {
      formSection.classList.add('hidden');
    }
    formElem.classList.remove('hidden');
    body.classList.remove('hidden');
    html.classList.remove('hidden');
  });
}
